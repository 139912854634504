/**
 * @file Navbar content configuration.
 * @copyright Yury Korotovskikh <u.korotovskiy@nil.foundation>
 */

import { getRuntimeConfigOrThrow } from '@/features/runtimeConfig';

const urlOrigin = getRuntimeConfigOrThrow().URL_ORIGIN;

const links: Array<Record<string, string>> = [
    {
        href: '/',
        title: 'Doom',
        active: 'true',
    },
    {
        href: `${urlOrigin}/#products`,
        title: 'Products',
        target: '_blank',
    },
    {
        href: `${urlOrigin}/about/`,
        title: 'About',
        target: '_blank',
    },
    {
        href: `${urlOrigin}/research/`,
        title: 'Research',
        target: '_blank',
    },
    {
        href: 'https://blog.nil.foundation/',
        title: 'Blog',
        target: '_blank',
    },
    {
        href: `${urlOrigin}/careers/`,
        title: 'Careers',
        target: '_blank',
    },
];

/**
 * Navbar configuration.
 */
const navbarConfig = {
    links,
    urlOrigin,
};

export default navbarConfig;
