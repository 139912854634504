/**
 * @file React hook.
 * @copyright Yury Korotovskikh <u.korotovskiy@nil.foundation>
 */

import { useEffect, useState } from 'react';
import { allElementsLoaded } from '../utils/loadedPageElements';

/**
 * Props.
 */
type UseHashFragmentProps = {
    /**
     * Time in milliseconds.
     *
     * @default 3000
     */
    maxWaitTime?: number;
};

/**
 * Scroll offset.
 */
const offset = 20;

let checkForElementInterval: NodeJS.Timer;
let checkForElementTimeout: NodeJS.Timer;

/**
 * Scrolls to element if page url contains hash.
 *
 * @param {UseHashFragmentProps} props Props.
 */
export const useHashFragment = ({ maxWaitTime = 3000 }: UseHashFragmentProps = {}) => {
    const [hash, setHash] = useState(getWindowHash());

    useEffect(() => {
        const hashChangeHadler = (e: HashChangeEvent) => {
            e.preventDefault();
            setHash(getWindowHash());
        };

        window.addEventListener('hashchange', hashChangeHadler);
        return () => {
            window.removeEventListener('hashchange', hashChangeHadler);
        };
    }, [setHash]);

    useEffect(() => {
        const cleanUpTimeouts = () => {
            clearInterval(checkForElementInterval);
            clearTimeout(checkForElementTimeout);
        };

        if (!hash) {
            return () => cleanUpTimeouts();
        }

        const scrollToHashElement = () => {
            const { element: elementToScroll } = getHashElement();

            if (elementToScroll && elementToScroll instanceof HTMLElement) {
                window.scrollTo({
                    top: elementToScroll.offsetTop - offset,
                    behavior: 'smooth',
                });

                cleanUpTimeouts();

                return true;
            }

            return false;
        };

        const tryScrollToHashElement = async () => {
            if (!scrollToHashElement()) {
                checkForElementInterval = setInterval(() => {
                    allElementsLoaded && scrollToHashElement();
                }, 200);

                checkForElementTimeout = setTimeout(
                    () => clearInterval(checkForElementInterval),
                    maxWaitTime,
                );
            }
        };

        tryScrollToHashElement();

        return () => cleanUpTimeouts();
    }, [hash, maxWaitTime]);
};

/**
 * @returns Window hash.
 */
const getWindowHash = () => (window.location.hash !== '#' ? window.location.hash : undefined);

/**
 * @returns HTMLelement or undefined.
 */
const getHashElement = () => {
    const hash = getWindowHash();
    const element = hash ? document.querySelector(window.location.hash) ?? undefined : undefined;

    return { element };
};
