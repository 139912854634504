/**
 * @file Api.
 * @copyright Yury Korotovskikh <u.korotovskiy@nil.foundation>
 */

//import { createApiClient } from "@/features/shared";
import type { LeaderboardData } from '../models/LeaderboardData';

//const httpFetcher = createApiClient({ prefixUrl: '' });

/**
 * Get leaderboard data.
 *
 * @todo Replace with real data.
 * @returns Leaderboard data.
 */
const getData = (): Promise<LeaderboardData[]> => Promise.resolve(mockLeaderboardData);
//httpFetcher.get('leaderboard').json();

/**
 * Leaderboard api.
 */
const LeaderboardApi = {
    getData,
};

export default LeaderboardApi;

/**
 * @todo Remove.
 */
const mockLeaderboardData = [
    {
        name: '0xd8dA6BF26964aF9D7eEd9e03E53415D37aA96045',
        ranking: 1,
        ens: '0xd8dA6BF26964aF9D7eEd9e03E53415D37aA96045',
        eth: '0xd8dA6BF26964aF9D7eEd9e03E53415D37aA96045',
        time: 19000,
    },
    {
        name: '0xd8dA6BF26964aF9D7eEd9e03E53415D37aA96045',
        ranking: 2,
        ens: '0xd8dA6BF26964aF9D7eEd9e03E53415D37aA96045',
        eth: '0xd8dA6BF26964aF9D7eEd9e03E53415D37aA96045',
        time: 19000,
    },
    {
        name: '0xd8dA6BF26964aF9D7eEd9e03E53415D37aA96045',
        ranking: 3,
        ens: '0xd8dA6BF26964aF9D7eEd9e03E53415D37aA96045',
        eth: '0xd8dA6BF26964aF9D7eEd9e03E53415D37aA96045',
        time: 19000,
    },
    {
        name: '0xd8dA6BF26964aF9D7eEd9e03E53415D37aA96045',
        ranking: 4,
        ens: '0xd8dA6BF26964aF9D7eEd9e03E53415D37aA96045',
        eth: '0xd8dA6BF26964aF9D7eEd9e03E53415D37aA96045',
        time: 19000,
    },
    {
        name: '0xd8dA6BF26964aF9D7eEd9e03E53415D37aA96045',
        ranking: 5,
        ens: '0xd8dA6BF26964aF9D7eEd9e03E53415D37aA96045',
        eth: '0xd8dA6BF26964aF9D7eEd9e03E53415D37aA96045',
        time: 19000,
    },
    {
        name: '0xd8dA6BF26964aF9D7eEd9e03E53415D37aA96045',
        ranking: 6,
        ens: '0xd8dA6BF26964aF9D7eEd9e03E53415D37aA96045',
        eth: '0xd8dA6BF26964aF9D7eEd9e03E53415D37aA96045',
        time: 19000,
    },
    {
        name: '0xd8dA6BF26964aF9D7eEd9e03E53415D37aA96045',
        ranking: 7,
        ens: '0xd8dA6BF26964aF9D7eEd9e03E53415D37aA96045',
        eth: '0xd8dA6BF26964aF9D7eEd9e03E53415D37aA96045',
        time: 19000,
    },
    {
        name: '0xd8dA6BF26964aF9D7eEd9e03E53415D37aA96045',
        ranking: 8,
        ens: '0xd8dA6BF26964aF9D7eEd9e03E53415D37aA96045',
        eth: '0xd8dA6BF26964aF9D7eEd9e03E53415D37aA96045',
        time: 19000,
    },
    {
        name: '0xd8dA6BF26964aF9D7eEd9e03E53415D37aA96045',
        ranking: 9,
        ens: '0xd8dA6BF26964aF9D7eEd9e03E53415D37aA96045',
        eth: '0xd8dA6BF26964aF9D7eEd9e03E53415D37aA96045',
        time: 19000,
    },
    {
        name: '0xd8dA6BF26964aF9D7eEd9e03E53415D37aA96045',
        ranking: 10,
        ens: '0xd8dA6BF26964aF9D7eEd9e03E53415D37aA96045',
        eth: '0xd8dA6BF26964aF9D7eEd9e03E53415D37aA96045',
        time: 19000,
    },
];
