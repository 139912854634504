/**
 * @file React component.
 * @copyright Yury Korotovskikh <u.korotovskiy@nil.foundation>
 */

import type { DetailedHTMLProps, HTMLAttributes, ReactElement } from 'react';
import clsx from 'clsx';
import { TBody } from './TBody';
import { TCell } from './TCell';
import { THead } from './THead';
import { TRow } from './TRow';
import { THeader } from './THeader';
import styles from './Table.module.scss';

/**
 * Props.
 */
type TableProps = Omit<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'role'>;

/**
 * Table.
 *
 * @param {TableProps} props Props.
 * @returns React component.
 */
export const Table = ({ className, children, ...rest }: TableProps): ReactElement => {
    return (
        <div
            role="table"
            className={clsx(styles.table, className)}
            {...rest}
        >
            {children}
        </div>
    );
};

Table.Body = TBody;
Table.Cell = TCell;
Table.Head = THead;
Table.Row = TRow;
Table.Header = THeader;
