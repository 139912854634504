/**
 * @file React component.
 * @copyright Yury Korotovskikh <u.korotovskiy@nil.foundation>
 */

import type { ReactElement } from 'react';
import clsx from 'clsx';
import { Loading } from '@/features/shared';
import { useGetLeaderboardData } from '../../hooks/useGetLeaderboardData';
import { LeaderboardTable } from '../leaderboardTable/LeaderboardTable';
import styles from './Leaderboard.module.scss';

/**
 * Props.
 */
type LeaderboardProps = {
    className?: string;
};

/**
 * Leaderboard.
 *
 * @param {LeaderboardProps} props Props.
 * @returns React component.
 */
export const Leaderboard = ({ className }: LeaderboardProps): ReactElement => {
    const leaderboardData = useGetLeaderboardData();

    return (
        <div className={clsx(className, styles.container)}>
            <h3 className={styles.title}>=NIL; leaderboard</h3>
            <LeaderboardViewFactory {...leaderboardData} />
        </div>
    );
};

const LeaderboardViewFactory = ({
    isInitialLoading,
    isError,
    data,
}: ReturnType<typeof useGetLeaderboardData>) => {
    switch (true) {
        case isInitialLoading:
            return <Loading />;
        case isError:
            return <div>Error while loading leaderboard data</div>;
        case data !== undefined:
            return <LeaderboardTable data={data!} />;
        default:
            return <></>;
    }
};
