/**
 * @file React hook.
 * @copyright Yury Korotovskikh <u.korotovskiy@nil.foundation>
 */

import { useState, useEffect } from 'react';
import { useWindowDimensions } from '../useWindowDimensions';

/**
 * Setup this values according to bootstrap breakpoints variables.
 */
const smBreakpoint = 375;
const mdBreakpoint = 744;
const lgBreakpoint = 1280;
const xlBreakpoint = 1792;

function getBreakpoint(width: number): Breakpoint {
    switch (true) {
        case width < smBreakpoint:
            return 'xs';
        case width < mdBreakpoint && width >= smBreakpoint:
            return 'sm';
        case width < lgBreakpoint && width >= mdBreakpoint:
            return 'md';
        case width < xlBreakpoint && width >= lgBreakpoint:
            return 'lg';
        default:
            return 'xl';
    }
}

/**
 * Hook provides easy usage of responsive breakpoints inside react components.
 *
 * @returns Current matching breakpoint.
 */
export const useBreakpoint = () => {
    const { width } = useWindowDimensions();
    const [breakpoint, setBreakpoint] = useState<Breakpoint>(getBreakpoint(width));

    useEffect(() => {
        setBreakpoint(getBreakpoint(width));
    }, [width]);

    return breakpoint;
};
