/**
 * @file Exports public feature interfaces.
 * @copyright Yury Korotovskikh <u.korotovskiy@nil.foundation>
 */

import loadable from '@loadable/component';

const Modal = loadable(() => import('./components/modal/Modal'), {
    fallback: <></>,
});
export { Modal };

export * from './components/loading/Loading';
export * from './components/layout/Layout';
export * from './components/grid';
export * from './components/logo/Logo';
export * from './components/queryClientProvider/QueryClientProvider';
export * from './components/table/Table';
export * from './components/icon/Icon';
export * from './components/anchor/Anchor';
export * from './components/button/Button';
export * from './components/skipLink/SkipLink';
export * from './components/collapseMenu/CollapseMenu';
export { siteTitleId } from './components/skipLink/skipLinkConfig';

export { useHover } from './hooks/useHover';
export { useBreakpoint } from './hooks/useBreakpoint/useBreakpoint';
export { useOnScroll } from './hooks/useOnScroll';
export { useScript } from './hooks/useScript';
export { useDisableRightClick } from './hooks/useDisableRightClick';

export { createApiClient } from './api/createApiClient';

export * from './dateUtils/format';
export * from './packages/localStorage/LocalStorage';

export * from './constants/socials';
