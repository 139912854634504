/**
 * @file React component.
 * @copyright Yury Korotovskikh <u.korotovskiy@nil.foundation>
 */

import type { ReactElement, HTMLAttributes, FocusEvent } from 'react';
import { useRef } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import clsx from 'clsx';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import { useBreakpoint } from '../../hooks/useBreakpoint/useBreakpoint';
import { useOnScroll } from '../../hooks/useOnScroll';
import styles from './CollapseMenu.module.scss';

/**
 * Props.
 */
type CollapseMenuProps = {
    isOpen: boolean;
    onClose: () => void;
    /**
     * Show Collapse container, if false show only children.
     */
    appear: Array<Breakpoint> | Breakpoint;
} & HTMLAttributes<HTMLDivElement>;

/**
 * Collapse menu.
 *
 * @param {CollapseMenuProps} props Props.
 * @returns React component.
 */
export const CollapseMenu = ({
    children,
    isOpen,
    onClose,
    className,
    appear,
    onBlur,
    ...restProps
}: CollapseMenuProps): ReactElement => {
    const ref = useRef(null);
    useOnClickOutside(ref, () => onClose(), isOpen);
    const breakpoint = useBreakpoint();
    useHotkeys('esc', () => onClose());
    useOnScroll(onClose);

    const showCollapse = () => {
        if (typeof appear === 'string') {
            return breakpoint === appear;
        }

        return appear.some(x => x === breakpoint);
    };

    const onBlurHandler = (e: FocusEvent<HTMLDivElement, Element>) => {
        if (!e.currentTarget.contains(e.relatedTarget)) {
            onClose();
            onBlur && onBlur(e);
        }
    };

    if (!showCollapse()) {
        return <>{children}</>;
    }

    return (
        <div
            ref={ref}
            className={clsx(className, styles.collapse, !isOpen && 'visually-hidden')}
            onBlur={onBlurHandler}
            {...restProps}
        >
            {children}
        </div>
    );
};
