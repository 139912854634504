/**
 * @file React component.
 * @copyright Yury Korotovskikh <u.korotovskiy@nil.foundation>
 */

import type { ReactElement } from 'react';
import clsx from 'clsx';
import { Table as T } from '@/features/shared';
import type { LeaderboardData } from '../../models/LeaderboardData';
import leaderboardTableConfig from './leaderboardTableConfiguration';
import { LeaderboardTableRow } from './LeaderboardTableRow';
import styles from './LeaderboardTable.module.scss';

/**
 * Props.
 */
type LeaderboardTableProps = {
    className?: string;
    data: LeaderboardData[];
    /** Hide scrollbar and overflow. */
    hideOverflow?: boolean;
};

/**
 * Leaderboard table.
 *
 * @param {LeaderboardTableProps} props Props.
 * @returns React component.
 */
export const LeaderboardTable = ({
    className,
    data,
    hideOverflow = true,
}: LeaderboardTableProps): ReactElement => {
    return (
        <T className={clsx(className, styles.table, hideOverflow && styles.hideOverflow)}>
            <T.Head>
                {leaderboardTableConfig.head.map(({ title, hidden }) => (
                    <T.Header key={title}>{hidden ? '' : title}</T.Header>
                ))}
            </T.Head>
            <T.Body>
                {data.map(data => (
                    <LeaderboardTableRow
                        key={data.ranking}
                        {...data}
                    />
                ))}
            </T.Body>
        </T>
    );
};
