/**
 * @file Loaded elements helper.
 */

const loadedElements = new Map(
    Object.entries({
        PipelineOverview: false,
        LetsPlay: false,
        HowToGetNft: false,
        AboutTheProject: false,
        AboutNilFoundation: false,
    } as Record<PageElement, boolean>),
) as Map<PageElement, boolean>;

/**
 * Is true when every page element is loaded.
 */
export let allElementsLoaded = false;

/**
 * @param e Element name.
 */
export const registerElementAsLoaded = (e: PageElement) => {
    const map = loadedElements.set(e, true);
    allElementsLoaded = Array.from(map.values()).every(x => !!x);
};
