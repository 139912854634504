/**
 * @file Enum declaration.
 * @copyright Yury Korotovskikh <u.korotovskiy@nil.foundation>
 */

import faceBasic from '../assets/face_basic.png';
import faceKnowMore from '../assets/face_know_more.png';
import faceLeft from '../assets/face_left.png';
import facePlayNow from '../assets/face_play_now.png';
import faceRight from '../assets/face_right.png';

/**
 * Watching head state.
 */
export const enum HeadState {
    neutral = 'neutral',
    right = 'right',
    left = 'left',
    play = 'play',
    know = 'know',
}

/**
 * Returns asset name without relative path, depending on head state.
 *
 * @param s Head state.
 * @returns Asset name.
 */
export const mapHeadStateToAsset = (s: HeadState) => {
    switch (s) {
        case HeadState.right:
            return faceRight;
        case HeadState.left:
            return faceLeft;
        case HeadState.play:
            return facePlayNow;
        case HeadState.know:
            return faceKnowMore;
        case HeadState.neutral:
        default:
            return faceBasic;
    }
};
