/**
 * @file React component.
 * @copyright Yury Korotovskikh <u.korotovskiy@nil.foundation>
 */

import type { ReactElement, ReactNode } from 'react';
import type { QueryClientConfig } from '@tanstack/react-query';
import { QueryClient, QueryClientProvider as OriginalProvider } from '@tanstack/react-query';

const queryConfig: QueryClientConfig = {};

const queryClient = new QueryClient(queryConfig);

/**
 * Props.
 */
type QueryClientProviderProps = {
    children?: ReactNode;
};

/**
 * React-query provider.
 *
 * @param {QueryClientProviderProps} props Props.
 * @returns React component.
 */
export const QueryClientProvider = ({ children }: QueryClientProviderProps): ReactElement => {
    return <OriginalProvider client={queryClient}>{children}</OriginalProvider>;
};
