/**
 * @file React component.
 * @copyright Yury Korotovskikh <u.korotovskiy@nil.foundation>
 */

import type { ReactElement, ReactNode } from 'react';
import loadable from '@loadable/component';
import { Navbar } from '../navbar/Navbar';
import { Loading } from '../loading/Loading';
import { Col, Container, Row } from '../grid';
import styles from './Layout.module.scss';

/**
 * Props.
 */
type LayoutProps = {
    children?: ReactNode;
};

const Footer = loadable(() => import('../footer/Footer'), {
    fallback: <Loading grow />,
});

/**
 * Layout component.
 *
 * @param {LayoutProps} props Props.
 * @returns React component.
 */
export const Layout = ({ children }: LayoutProps): ReactElement => {
    return (
        <Container
            className={styles.container}
            fluid
        >
            <Row>
                <Navbar />
            </Row>
            <Row
                as="main"
                className={styles.main}
            >
                <Col
                    xs={12}
                    md={9}
                >
                    {children}
                </Col>
            </Row>
            <Row>
                <Col className={styles.footerCol}>
                    <Footer />
                </Col>
            </Row>
        </Container>
    );
};
