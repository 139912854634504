/**
 * @file React hook.
 * @copyright Yury Korotovskikh <u.korotovskiy@nil.foundation>
 */

import { useEffect } from 'react';
import debounce from 'lodash.debounce';

/**
 * Hook to do some actions on scroll.
 *
 * @param callback Callback. Should be memoized.
 */
export const useOnScroll = (callback: () => void) => {
    useEffect(() => {
        const debouncedCb = debounce(callback, 200);

        document.addEventListener('scroll', debouncedCb);

        return (): void => {
            document.removeEventListener('scroll', debouncedCb);
        };
    }, [callback]);
};
