/**
 * @file React component.
 * @copyright Yury Korotovskikh <u.korotovskiy@nil.foundation>
 */

import { memo } from 'react';
import clsx from 'clsx';
import styles from './Logo.module.scss';

/**
 * Props.
 */
type LogoProps = {
    className?: string;
};

/**
 * Logo component.
 *
 * @param {LogoProps} props Props.
 * @returns React component.
 */
const _Logo = ({ className }: LogoProps) => {
    return (
        <span className={clsx(className, styles.logo)}>
            <code>=nil;</code> Foundation
        </span>
    );
};

/**
 * Memoized logo component.
 *
 * @returns React component.
 */
export const Logo = memo(_Logo);
