/**
 * @file React component.
 * @copyright Yury Korotovskikh <u.korotovskiy@nil.foundation>
 */

import type { DetailedHTMLProps, HTMLAttributes, ReactElement } from 'react';
import clsx from 'clsx';
import styles from './Table.module.scss';

/**
 * Props.
 */
type TCellProps = Omit<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'role'>;

/**
 * Table cell.
 *
 * @param {TCellProps} props Props.
 * @returns React component.
 */
export const TCell = ({ children, className, ...rest }: TCellProps): ReactElement => {
    return (
        <div
            role="cell"
            className={clsx(styles.cell, className)}
            {...rest}
        >
            {children}
        </div>
    );
};
