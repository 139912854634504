/**
 * @file Exports public feature interfaces.
 * @copyright Yury Korotovskikh <u.korotovskiy@nil.foundation>
 */

import loadable from '@loadable/component';
import { Loading } from '../shared';

const ErrorPage = loadable(() => import('./components/errorPage/ErrorPage'), {
    fallback: <Loading grow />,
});

export { ErrorPage };
export { configureSentry } from './utils/configureSentry';
