/**
 * @file Google analytics setup.
 * @copyright Yury Korotovskikh 2023 <u.korotovskiy@nil.foundation>
 */

import ReactGa from 'react-ga4';
import { getRuntimeConfigOrThrow } from '@/features/runtimeConfig';

/**
 * Ga initialize options.
 */
const gaInitOptions = {
    testMode: !import.meta.env.PROD,
};

/**
 * Configures ga for the app.
 * This code should be executed before any GA events.
 */
export const configureGA = (): void => {
    const measurementId = getRuntimeConfigOrThrow().GA_TRACKING_ID;

    if (!measurementId) {
        return;
    }

    ReactGa.initialize(measurementId, gaInitOptions);
};
