/**
 * @file React hook.
 * @copyright Yury Korotovskikh <u.korotovskiy@nil.foundation>
 */

import { useQuery } from '@tanstack/react-query';
import { leaderboardQueryKey } from './leaderboardQueryKey';
import LeaderboardApi from '../api/LeaderboardApi';

/**
 * Hook to get, cache and revalidate leaderboard data.
 *
 * @returns Leaderboard data state.
 */
export const useGetLeaderboardData = () => {
    const query = useQuery({ queryKey: [leaderboardQueryKey], queryFn: LeaderboardApi.getData });

    return query;
};
