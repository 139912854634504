/**
 * @file React component.
 * @copyright Yury Korotovskikh <u.korotovskiy@nil.foundation>
 */

import { forwardRef } from 'react';
import type { ButtonProps as BSButtonProps, SpinnerProps } from 'react-bootstrap';
import { Button as BSButton, Spinner } from 'react-bootstrap';
import { Icon } from '../icon/Icon';
import styles from './Button.module.scss';

/**
 * Props.
 */
type ButtonProps = {
    sm?: boolean;
    outline?: boolean;
    isLoading?: boolean;
    spinnerProps?: SpinnerProps;
    hasArrow?: boolean;
} & Omit<BSButtonProps, 'variant' | 'size'>;

/**
 * Button component.
 *
 * @param {ButtonProps} props Props.
 * @returns React component.
 */
export const Button = forwardRef<HTMLButtonElement, ButtonProps>(function Button(
    { isLoading, children, spinnerProps, outline, sm, hasArrow, ...props },
    ref,
) {
    const variant = outline ? 'outline-primary' : 'primary';
    const size = sm ? 'sm' : undefined;

    return (
        <BSButton
            variant={variant}
            size={size}
            ref={ref}
            {...props}
        >
            {isLoading && (
                <span className={styles.loading}>
                    <Spinner {...spinnerProps} />
                </span>
            )}
            {children}
            {hasArrow && (
                <Icon
                    name="fa-solid fa-angle-right"
                    className={styles.icon}
                />
            )}
        </BSButton>
    );
});
