/**
 * @file React component.
 * @copyright Yury Korotovskikh <u.korotovskiy@nil.foundation>
 */

import type { ComponentProps, ElementType, ReactElement, ReactNode } from 'react';
import { useState } from 'react';
import { HeadState } from '../../enums/HeadState';
import { WatchingHeadContext } from './WatchingHeadContext';

/**
 * Props.
 */
type WatchingHeadProviderOwnProps<AS extends ElementType = ElementType> = {
    children?: ReactNode;
    /**
     * Element to render.
     */
    as?: AS;
};

/**
 * WatchingHeadProvider component own props with generic element props.
 */
export type WatchingHeadProviderProps<AS extends ElementType = ElementType> =
    WatchingHeadProviderOwnProps<AS> &
        Omit<ComponentProps<AS>, keyof WatchingHeadProviderOwnProps<AS>>;

/**
 * Watching head provider.
 *
 * @param {WatchingHeadProviderProps} props Props.
 * @returns React component.
 */
export const WatchingHeadProvider = <AS extends ElementType = ElementType>({
    children,
    as,
}: WatchingHeadProviderProps<AS>): ReactElement => {
    const [headState, setHeadState] = useState<HeadState | undefined>(HeadState.neutral);
    const [headIsVisible, setHeadIsVisible] = useState<boolean>();
    const Component: ElementType | undefined = as;

    return (
        <WatchingHeadContext.Provider
            value={{
                headState: headState ?? HeadState.neutral,
                setHeadState,
                headIsVisible,
                setHeadIsVisible,
            }}
        >
            {Component === undefined ? children : <Component>{children}</Component>}
        </WatchingHeadContext.Provider>
    );
};
