/**
 * @file Root index.
 * @copyright Yury Korotovskikh <u.korotovskiy@nil.foundation>
 */

import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { checkRuntimeConfig } from './features/runtimeConfig';
import { QueryClientProvider } from './features/shared';
import { WatchingHeadProvider } from './features/watchingHead';
import { configureGA, LocationTracker } from './features/googleAnalytics';
import { reportWebVitals } from './reportWebVitals';
import { configureSentry } from './features/sentry';
import './styles/index.scss';

configureGA();
configureSentry();
checkRuntimeConfig();

createRoot(document.getElementById('root') as HTMLElement).render(
    <StrictMode>
        <QueryClientProvider>
            <WatchingHeadProvider>
                <App />
                <LocationTracker />
            </WatchingHeadProvider>
        </QueryClientProvider>
    </StrictMode>,
);

reportWebVitals();
