/**
 * @file Leaderboard table configuration.
 * @copyright Yury Korotovskikh <u.korotovskiy@nil.foundation>
 */

/**
 * Table header config.
 */
type HeaderConfig = {
    title: string;
    hidden?: boolean;
};

const head: HeaderConfig[] = [
    {
        title: 'Ranking',
    },
    {
        title: 'Time',
    },
    {
        title: 'Name',
    },
    {
        title: 'InfoIcon',
        hidden: true,
    },
];

/**
 * LeaderboardTable configuration.
 */
const leaderboardTableConfig = {
    head,
};

export default leaderboardTableConfig;
