/**
 * @file Exports public feature interfaces.
 * @copyright Yury Korotovskikh <u.korotovskiy@nil.foundation>
 */

import loadable from '@loadable/component';
import { Loading } from '../shared';

export { letsPlaySectionId } from './constants/letsPlaySectionId';

const LetsPlay = loadable(() => import('./components/LetsPlay/LetsPlay'), {
    fallback: <Loading grow />,
});

export { LetsPlay };
