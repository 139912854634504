/**
 * @file React component.
 * @copyright Yury Korotovskikh <u.korotovskiy@nil.foundation>
 */

import { getRuntimeConfigOrThrow } from '@/features/runtimeConfig';
import { Container, Row, Col, Button, Icon, siteTitleId } from '@/features/shared';
import { HeadState, WatchingHeadTarget } from '@/features/watchingHead';
import { letsPlaySectionId } from '@/features/letsPlay';
import { Leaderboard } from '@/features/leaderboard';
// import { TellMeMoreCard } from '../tellMeMoreCard/TellMeMoreCard';
import styles from './SiteHead.module.scss';

const blogPostUrl = getRuntimeConfigOrThrow().BLOG_POST_URL;

/**
 * Leaderboard section title component.
 *
 * @returns React component.
 */
export const SiteHead = () => {
    return (
        <Container fluid>
            <Row>
                <h1
                    id={siteTitleId}
                    className={styles.title}
                >
                    Proving Doom to Ethereum
                </h1>
            </Row>
            <Row>
                <Col
                    xs={12}
                    lg={6}
                    className={styles.col}
                >
                    <Icon name="fa-solid fa-info-circle" />
                    <span className={styles.text}>
                        Discover zkLLVM and Proof Market, enabling zkProofs for C++ computations on
                        Ethereum.
                    </span>
                    <span className={styles.text}>
                        Compete for leaderboard spots and earn exclusive Doom NFTs.
                    </span>
                </Col>
                <Col
                    xs={12}
                    lg={9}
                    className={styles.col}
                >
                    <WatchingHeadTarget<HTMLButtonElement> headState={HeadState.play}>
                        {({ ref }) => (
                            <Button
                                ref={ref}
                                as="a"
                                href={`#${letsPlaySectionId}`}
                                hasArrow
                                className={styles.btn}
                            >
                                New game
                            </Button>
                        )}
                    </WatchingHeadTarget>
                </Col>
                <Col
                    xs={12}
                    lg={3}
                    className={styles.col}
                >
                    <WatchingHeadTarget<HTMLButtonElement> headState={HeadState.know}>
                        {({ ref }) => (
                            <Button
                                ref={ref}
                                outline
                                as="a"
                                href={blogPostUrl}
                                className={styles.btn}
                            >
                                Learn more
                            </Button>
                        )}
                    </WatchingHeadTarget>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Leaderboard />
                </Col>
            </Row>
            {/* <Row as={TellMeMoreCard} /> */}
        </Container>
    );
};
