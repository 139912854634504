/**
 * @file React component.
 * @copyright Yury Korotovskikh <u.korotovskiy@nil.foundation>
 */

import { useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import clsx from 'clsx';
import { skipLinkConfig } from './skipLinkConfig';
import styles from './SkipLink.module.scss';

/**
 * Skip link component.
 *
 * @returns React component.
 */
export const SkipLink = () => {
    const [show, setShow] = useState(false);

    return (
        <Nav.Link
            href={skipLinkConfig.href}
            onFocus={() => setShow(true)}
            onBlur={() => setShow(false)}
            className={clsx(show ? '' : 'visually-hidden', styles.link)}
        >
            {skipLinkConfig.title}
        </Nav.Link>
    );
};
