/**
 * @file React hook.
 * @copyright Yury Korotovskikh <u.korotovskiy@nil.foundation>
 */

import { useCallback, useContext, useEffect, useRef } from 'react';
import { useOnScreen } from '@ukorvl/react-on-screen';
import { WatchingHeadContext } from '../components/watchingHeadProvider/WatchingHeadContext';

/**
 * @returns Head state and callback to register HTML element as a head.
 */
export const useRegisterWatchingHead = () => {
    const { setHeadState, headState, setHeadIsVisible } = useContext(WatchingHeadContext);

    if (import.meta.env.DEV && setHeadState === undefined) {
        throw new Error(
            'useRegisterWatchingHead hook can be used only inside WatchingHead context',
        );
    }

    const containerRef = useRef(null);
    const { isOnScreen } = useOnScreen({
        ref: containerRef,
        margin: '200px',
        initialVisibility: true,
    });

    useEffect(() => {
        setHeadIsVisible(isOnScreen);
    }, [setHeadIsVisible, isOnScreen]);

    /**
     * Allows to register HTML element as a watching head.
     *
     * @example ```tsx
     * <div {...registerHead()} />
     * ```
     */
    const registerHead = useCallback(() => ({ ref: containerRef }), [containerRef]);

    return { registerHead, headState };
};
