/**
 * @file Exports public feature interfaces.
 * @copyright Yury Korotovskikh <u.korotovskiy@nil.foundation>
 */

import loadable from '@loadable/component';
import { Loading } from '../shared';

const PipelineOverview = loadable(() => import('./components/piplineOverview/PipelineOverview'), {
    fallback: <Loading grow />,
});

export { PipelineOverview };
