/**
 * @file React hook.
 * @copyright Yury Korotovskikh <u.korotovskiy@nil.foundation>
 */

import type { RefObject } from 'react';
import { useEffect } from 'react';

/**
 * Hook to manage click outside element.
 *
 * @param ref Ref.
 * @param closeHandler Close handler.
 * @param isElementVisible Is element visible.
 */
export const useOnClickOutside = <T extends HTMLElement>(
    ref: RefObject<T>,
    closeHandler: () => void,
    isElementVisible: boolean,
) => {
    useEffect(() => {
        const handleClick = (evt: Event): void => {
            if (!isElementVisible) {
                return;
            }

            if (!ref.current) {
                return;
            }

            if (!(evt.target instanceof Node)) {
                return;
            }

            if (ref.current.contains(evt.target)) {
                return;
            }

            closeHandler();
            evt.stopPropagation();
            evt.preventDefault();
        };

        document.addEventListener('click', handleClick, { capture: true });

        return (): void => {
            document.removeEventListener('click', handleClick, {
                capture: true,
            });
        };
    }, [ref, closeHandler, isElementVisible]);
};
