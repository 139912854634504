/**
 * @file React component.
 * @copyright Yury Korotovskikh <u.korotovskiy@nil.foundation>
 */

import type { ReactElement } from 'react';
import { format, Icon, Table as T } from '@/features/shared';
import type { LeaderboardData } from '../../models/LeaderboardData';
import styles from './LeaderboardTable.module.scss';

/**
 * Props.
 */
type LeaderboardTableRowProps = LeaderboardData;

/**
 * Leaderboard table row.
 *
 * @todo Display ENS in name column instead of ETH address if exists.
 * @param {LeaderboardTableRowProps} props Props.
 * @returns React component.
 */
export const LeaderboardTableRow = ({
    name,
    ranking,
    time,
}: LeaderboardTableRowProps): ReactElement => {
    return (
        <T.Row>
            <T.Cell>
                <span className={styles.text}>{`#${ranking}`}</span>
            </T.Cell>
            <T.Cell>
                <span className={styles.text}>{format(time, 'HH:mm:ss')}</span>
            </T.Cell>
            <T.Cell>
                <span className={styles.text}>{name}</span>
            </T.Cell>
            <T.Cell className={styles.iconContainer}>
                <Icon
                    className={styles.infoIcon}
                    name="fa-solid fa-info-circle"
                    srOnlyText="Show more information about user result"
                />
            </T.Cell>
        </T.Row>
    );
};
