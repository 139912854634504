/**
 * @file React component.
 * @copyright Yury Korotovskikh <u.korotovskiy@nil.foundation>
 */

import { useState } from 'react';
import BsNavbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import clsx from 'clsx';
import { Container } from '../grid';
import { Logo } from '../logo/Logo';
import navbarConfig from './navbarConfiguration';
import { SkipLink } from '../skipLink/SkipLink';
import { CollapseMenu } from '../collapseMenu/CollapseMenu';
import styles from './Navbar.module.scss';

const menuId = 'menu';

/**
 * Navbar component.
 *
 * @returns React component.
 */
export const Navbar = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    return (
        <BsNavbar
            className={styles.navbar}
            collapseOnSelect
            variant="dark"
            expand="lg"
        >
            <SkipLink />
            <Container>
                <BsNavbar.Brand
                    href={navbarConfig.urlOrigin}
                    className={styles.brand}
                >
                    <Logo />
                </BsNavbar.Brand>
                <BsNavbar.Toggle
                    aria-controls={menuId}
                    onClick={() => setMenuOpen(!menuOpen)}
                />
                <CollapseMenu
                    id={menuId}
                    isOpen={menuOpen}
                    onClose={() => setMenuOpen(false)}
                    appear={['xs', 'sm', 'md']}
                >
                    <Nav className={clsx(menuOpen && styles.navInsideCollapseMenu, styles.nav)}>
                        {navbarConfig.links.map(({ title, ...rest }) => (
                            <Nav.Link
                                key={title}
                                {...rest}
                            >
                                {title}
                            </Nav.Link>
                        ))}
                    </Nav>
                </CollapseMenu>
            </Container>
        </BsNavbar>
    );
};
