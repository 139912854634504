/**
 * @file React component.
 * @copyright Yury Korotovskikh <u.korotovskiy@nil.foundation>
 */

import { memo, useEffect } from 'react';
import ReactGa from 'react-ga4';

/**
 * Location tracker component.
 *
 * @returns React component.
 */
const _LocationTracker = (): null => {
    useEffect(() => {
        ReactGa.send({ hitType: 'pageview', page: window.location.href });
    }, []);

    return null;
};

/**
 * Memoized location tracker component.
 */
export const LocationTracker = memo(_LocationTracker);
