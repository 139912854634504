/**
 * @file React component.
 * @copyright Yury Korotovskikh <u.korotovskiy@nil.foundation>
 */

import { ErrorBoundary, withProfiler } from '@sentry/react';
import { AboutTheProject } from './features/aboutTheProject';
import { Layout } from './features/shared';
import { useHashFragment } from './features/hashFragment';
import { PipelineOverview } from './features/piplineOverview';
import { HeadState, WatchingHead, WatchingHeadTarget } from './features/watchingHead';
import { SiteHead } from './features/siteHead';
// import { HowToGetNft } from './features/howToGetNFT';
import { LetsPlay } from './features/letsPlay';
import { AboutNilFoundation } from './features/aboutNilFoundation';
import { ErrorPage } from './features/sentry';
import styles from './App.module.scss';

/**
 * @returns App.
 */
function App() {
    useHashFragment();

    return (
        <ErrorBoundary fallback={<ErrorPage />}>
            <Layout>
                <div className={styles.gradientBg}>
                    <WatchingHeadTarget<HTMLDivElement> headState={HeadState.left}>
                        {({ ref }) => (
                            <div
                                ref={ref}
                                className={styles.leftWatchingHeadTarget}
                            />
                        )}
                    </WatchingHeadTarget>
                    <WatchingHeadTarget<HTMLDivElement> headState={HeadState.right}>
                        {({ ref }) => (
                            <div
                                ref={ref}
                                className={styles.rightWatchingHeadTarget}
                            />
                        )}
                    </WatchingHeadTarget>
                    <WatchingHead />
                    <SiteHead />
                </div>
                <PipelineOverview />
                <AboutTheProject />
                {/* <HowToGetNft /> */}
                <LetsPlay />
                <AboutNilFoundation />
            </Layout>
        </ErrorBoundary>
    );
}

export default withProfiler(App);
