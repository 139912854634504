/**
 * @file React component.
 * @copyright Yury Korotovskikh <u.korotovskiy@nil.foundation>
 */

import { useContext } from 'react';
import clsx from 'clsx';
import { useBreakpoint } from '@/features/shared';
import { WatchingHeadContext } from '../watchingHeadProvider/WatchingHeadContext';
import { mapHeadStateToAsset } from '../../enums/HeadState';
import { useRegisterWatchingHead } from '../../hooks/useRegisterHead';
import styles from './WatchingHead.module.scss';

/**
 * Props.
 */
type WatchingHeadProps = {
    className?: string;
};

const imageDimensionsRatio = 0.85;

/**
 * Watching head component.
 *
 * @param {WatchingHeadProps} props Props.
 * @returns React component.
 */
export const WatchingHead = ({ className }: WatchingHeadProps) => {
    const { headState } = useContext(WatchingHeadContext);
    const { registerHead } = useRegisterWatchingHead();
    const breakpoint = useBreakpoint();
    const height =
        breakpoint === 'xs' || breakpoint === 'sm' ? 130 : breakpoint === 'md' ? 192 : 250;
    const width = height * imageDimensionsRatio;

    return (
        <div
            {...registerHead()}
            className={clsx(className, styles.container)}
        >
            <div className={styles.bg}>
                <img
                    src={mapHeadStateToAsset(headState)}
                    alt="Doom guy head"
                    className={styles.img}
                    height={height}
                    width={width}
                />
            </div>
        </div>
    );
};
