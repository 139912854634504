/**
 * @file React component.
 * @copyright Yury Korotovskikh <u.korotovskiy@nil.foundation>
 */

import { createContext } from 'react';
import type { HeadState } from '../../enums/HeadState';

/**
 * Context type.
 */
type WatchingHeadContextModel = {
    headState: HeadState;
    setHeadState: (s?: HeadState) => void;
    headIsVisible?: boolean;
    setHeadIsVisible: (v?: boolean) => void;
};

/**
 * Watching head context.
 */
export const WatchingHeadContext = createContext<WatchingHeadContextModel>(
    {} as WatchingHeadContextModel,
);
