/**
 * @file React component.
 * @copyright Yury Korotovskikh <u.korotovskiy@nil.foundation>
 */

import type { ForwardedRef, ReactElement } from 'react';
import { forwardRef } from 'react';
import clsx from 'clsx';
import styles from './Loading.module.scss';

/**
 * Props.
 */
type LoadingProps = {
    /** Takes all avialiable space. */
    grow?: boolean;
    className?: string;
    size?: LoadingSize;
};

/**
 * Loading size.
 */
type LoadingSize = 'sm' | 'md' | 'lg';

/**
 * Loading indicator component.
 *
 * @param {LoadingProps} props Props.
 * @returns React component.
 */
export const Loading = forwardRef<SVGSVGElement, LoadingProps>(function Loading(
    { grow = false, className, size = 'lg' },
    ref,
): ReactElement {
    if (grow) {
        return <div className={styles.grow}>{renderLoading({ className, size, ref })}</div>;
    }

    return renderLoading({ className, size, ref });
});

/**
 * @param {LoadingProps} props Props.
 * @returns Loading markup.
 */
function renderLoading({
    size,
    className,
    ref,
}: LoadingProps & { ref: ForwardedRef<SVGSVGElement> }) {
    const edgeSize = getEdgeSize(size);
    const sizeUnit = edgeSize / 5;
    const edgeHeight = edgeSize / 16;

    return (
        <svg
            width={edgeSize}
            height={edgeSize}
            viewBox={`${-edgeSize / 2} ${-edgeSize / 2} ${edgeSize} ${edgeSize}`}
            className={clsx(className, styles.loading)}
            ref={ref}
            aria-busy="true"
            aria-live="polite"
        >
            {[...new Array(4).keys()].map(x => (
                <rect
                    key={x}
                    className={styles.edge}
                    width={sizeUnit * 2}
                    height={edgeHeight}
                    transform={`rotate(${90 * x})`}
                    rx={edgeHeight / 2}
                    ry={edgeHeight / 2}
                    x={sizeUnit / 2}
                    y={-edgeHeight / 2}
                />
            ))}
        </svg>
    );
}

/**
 * @param size Size.
 * @returns Returns loading edge size.
 */
function getEdgeSize(size?: LoadingSize): number {
    switch (size) {
        case 'sm':
            return 40;
        case 'md':
            return 60;
        case 'lg':
        default:
            return 80;
    }
}
