/**
 * @file Skip link configuration.
 * @copyright Yury Korotovskikh <u.korotovskiy@nil.foundation>
 */

/**
 * Content id to jump after pressing the link.
 */
export const siteTitleId = 'siteTitle';

/**
 * Skip link config.
 */
export const skipLinkConfig = {
    href: `#${siteTitleId}`,
    title: 'Skip to content',
};
