/**
 * @file Check if all required runtimeConfig values are set.
 * @copyright Yury Korotovskikh <u.korotovskiy@nil.foundation>
 */

import { getRuntimeConfigOrThrow } from './getRuntimeConfigOrThrow';

const runtimeConfig = getRuntimeConfigOrThrow();

const requiredEnvs: Array<keyof typeof window.RUNTIME_CONFIG> = [
    'PROOF_MARKET_API_URL',
    'BLOG_POST_URL',
    'PROOF_MARKET_STATEMENT_KEY',
    'PROOF_MARKET_API_VERSION',
    'PROOF_MARKET_DB',
    'PROOF_MARKET_READONLY_LOGIN',
    'PROOF_MARKET_READONLY_PASSWORD',
    'PROOF_MARKET_STATEMENT_URL',
    'URL_ORIGIN',
];

/**
 * Check if all requred runtimeConfig values are set.
 *
 * @throws
 */
export const checkRuntimeConfig = () => {
    requiredEnvs.forEach(x => {
        if (runtimeConfig[x] === undefined) {
            throw new Error(`${x} runtimeConfig value is required but not set`);
        }
    });

    if (!import.meta.env.PROD) {
        console.log('Required runtimeConfig values are set.');
    }
};
