/**
 * @file React component.
 * @copyright Yury Korotovskikh <u.korotovskiy@nil.foundation>
 */

import type { HTMLAttributes, ReactElement } from 'react';
import { forwardRef, memo } from 'react';
import clsx from 'clsx';

/**
 * Props.
 */
type IconProps = {
    name: string;
    /**
     * Text, availiable only for screen readers.
     */
    srOnlyText?: string;
    className?: string;
} & HTMLAttributes<HTMLElement>;

/**
 * Icon component.
 *
 * @param {IconProps} props - Props.
 * @returns React component.
 */
const _Icon = forwardRef<HTMLSpanElement, IconProps>(function Icon(
    { name, className, srOnlyText, 'aria-hidden': ariaHidden = true, ...other },
    ref,
): ReactElement {
    return (
        <>
            <i
                className={clsx(className, name)}
                aria-hidden={ariaHidden}
                ref={ref}
                {...other}
            />
            {srOnlyText && <span className="sr-only">{srOnlyText}</span>}
        </>
    );
});

/**
 * Memoized icon component.
 */
export const Icon = memo(_Icon);
