/**
 * @file React component.
 * @copyright Yury Korotovskikh <u.korotovskiy@nil.foundation>
 */

import type { DetailedHTMLProps, HTMLAttributes, ReactElement } from 'react';
import clsx from 'clsx';
import styles from './Table.module.scss';

/**
 * Props.
 */
type THeadProps = {
    /** @default True. */
    borderBottom?: boolean;
} & Omit<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'role'>;

/**
 * Table head.
 *
 * @param {THeadProps} props Props.
 * @returns React component.
 */
export const THead = ({
    className,
    children,
    borderBottom = true,
    ...rest
}: THeadProps): ReactElement => {
    return (
        <div
            role="rowgroup"
            className={clsx(styles.head, className, borderBottom ? styles.headWithBorder : '')}
            {...rest}
        >
            {children}
        </div>
    );
};
