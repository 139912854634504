/**
 * @file Exports public feature interfaces.
 * @copyright Yury Korotovskikh <u.korotovskiy@nil.foundation>
 */

import loadable from '@loadable/component';
import { Loading } from '../shared';

const AboutNilFoundation = loadable(() => import('./components/AboutNilFoundation'), {
    fallback: <Loading grow />,
});

export { AboutNilFoundation };
