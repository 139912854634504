/**
 * @file Utility function.
 * @copyright Yury Korotovskikh <u.korotovskiy@nil.foundation>
 */

import dayjs from 'dayjs';

type DateTimeFormat = 'HH:mm:ss';

/**
 * Formats date string.
 *
 * @param date - Date string.
 * @param format - Format.
 * @returns Formatted date string representation.
 */
export const format = (date: string | number, format: DateTimeFormat): string =>
    dayjs(date).format(format);
