/**
 * @file React component.
 * @copyright Yury Korotovskikh <u.korotovskiy@nil.foundation>
 */

import type { ComponentProps, ElementType, ReactElement, RefObject } from 'react';
import { useEffect, useContext } from 'react';
import { useBreakpoint, useHover } from '@/features/shared';
import { WatchingHeadContext } from '../watchingHeadProvider/WatchingHeadContext';
import type { HeadState } from '../../enums/HeadState';

/**
 * WatchingHead own props.
 */
type WatchingHeadTargetOwnProps<T extends HTMLElement, AS extends ElementType = ElementType> = {
    children: (props: { ref: RefObject<T> }) => ReactElement;
    as?: AS;
    headState: HeadState;
};

/**
 * WatchingHead component props with generic element props.
 */
export type WatchingHeadTargetProps<
    T extends HTMLElement,
    AS extends ElementType = ElementType,
> = WatchingHeadTargetOwnProps<T, AS> &
    Omit<ComponentProps<AS>, keyof WatchingHeadTargetOwnProps<T, AS>>;

/**
 * Watching head component.
 *
 * @param {WatchingHeadTargetProps} props Props.
 * @returns React component.
 */
export const WatchingHeadTarget = <T extends HTMLElement, AS extends ElementType = ElementType>({
    as,
    children,
    headState: headStateToTrack,
    ...restProps
}: WatchingHeadTargetProps<T, AS>) => {
    const { setHeadState, headIsVisible } = useContext(WatchingHeadContext);
    const Component: ElementType | undefined = as;

    if (import.meta.env.DEV && setHeadState === undefined) {
        throw new Error(
            'useRegisterWatchingTarget hook can be used only inside WatchingHead context',
        );
    }

    const [ref, isHovered] = useHover<T>();
    const breakpoint = useBreakpoint();
    const diabledHead = breakpoint === 'xs' || breakpoint === 'sm';

    useEffect(() => {
        if (diabledHead) {
            return;
        }

        if (headIsVisible && isHovered) {
            setHeadState(headStateToTrack);
        }
    }, [isHovered, headIsVisible, setHeadState, headStateToTrack, diabledHead]);

    if (Component !== undefined) {
        return <Component {...restProps}>{children({ ref })}</Component>;
    }

    return children({ ref });
};
